<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="ACReport" />
    <v-container fluid>
      <v-card>
        <v-row justify="space-between" class="pa-3">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="7" style="text-align: right !important">
            <v-btn
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>

            <v-btn
              outlined
              @click="exportExcel()"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row v-if="showFilterTools" class="ma-1 px-2">
          <v-col style="margin-top: 14px" lg="3">
            <v-row>
              <v-col cols="12" lg="6">
                <label
                  for="createdateM"
                  style="
                    font-size: 16px;
                    color: #424242;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("createdateM") }}</label
                >
                <v-menu
                  v-model="frommenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      placeholder="yyyy/mm/dd"
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startdate"
                    @change="StartViewDate(), (frommenu = false)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <label
                  for="enddate"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("enddate") }}</label
                >
                <v-menu
                  v-model="tomenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      placeholder="yyyy/mm/dd"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="enddate"
                    :allowed-dates="allowedEndDates"
                    @input="tomenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <!-- Start Date && End Date -->
          <v-col style="margin-top: 14px" lg="3">
            <v-row>
              <v-col cols="12" lg="6">
                <label
                  for="startdate"
                  style="
                    font-size: 16px;
                    color: #424242;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("startdate") }}</label
                >
                <v-menu
                  v-model="frommenu_New"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate_New"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      placeholder="yyyy/mm/dd"
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startdate_New"
                    @change="StartViewDate_New(), (frommenu_New = false)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <label
                  for="enddate"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("enddate") }}</label
                >
                <v-menu
                  v-model="tomenu_New"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddate_New"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      placeholder="yyyy/mm/dd"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="enddate_New"
                    :allowed-dates="allowedEndDates_New"
                    @input="tomenu_New = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="showFilterTools" class="ma-1 px-2">
          <v-col cols="12" lg="9">
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="5" xl="4" class="pre-fix">
                <label
                  for="course"
                  style="
                    font-size: 16px;
                    color: #424242;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("course") }}:</label
                >
                <v-select
                  :items="Courseitems"
                  hide-no-data
                  v-model="course"
                  hide-details
                  item-text="courseName"
                  item-value="courseID"
                  solo
                  dense
                  @change="(event) => SelectCourse(event)"
                  @blur="clearSearchWord()"
                  multiple
                  clearable
                  placeholder="-- Please select --"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchcourse"
                          :placeholder="$t('search')"
                          @input="searchInLists()"
                          autocomplete="off"
                          hide-details
                          class="searchinlist"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item ripple @click="getSelectAll">
                      <v-list-item-action>
                        <v-icon :color="course.length > 0 ? '#a6cc39' : ''">{{
                          icon
                        }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.courseName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ course.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="4" lg="5" xl="4" class="pre-fix">
                <label
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("title") }}:</label
                >
                <v-select
                  v-model="values"
                  :items="Titleitems"
                  hide-no-data
                  hide-details
                  solo
                  dense
                  @change="(event) => SelectTitle(event)"
                  @blur="clearSearchWord()"
                  item-text="title"
                  item-value="titleID"
                  clearable
                  placeholder="-- Please select --"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchtitle"
                          :placeholder="$t('search')"
                          @input="searchInLists()"
                          autocomplete="off"
                          hide-details
                          class="searchinlist"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item ripple @click="SelectAllTitle">
                      <v-list-item-action>
                        <v-icon :color="values.length > 0 ? '#a6cc39' : ''">{{
                          Titleicon
                        }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.title }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ values.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-col> -->
              <v-col cols="12" lg="5" xl="4" md="4" style="padding-top: 2.2rem">
                <v-btn
                  text
                  class="text-capitalize"
                  height="40"
                  style="
                    border: 1px solid #c0c0c0;
                    border-radius: 8px;
                    color: #424242;
                    font-size: 16px;
                  "
                  @click="clearAll()"
                  >{{ $t("clearall") }}</v-btn
                >
                <v-btn
                  width="120"
                  height="40"
                  class="text-capitalize btn_hover_effect ml-5"
                  style="
                    color: #ffff;
                    border: 1px solid #a6cc39;
                    border-radius: 8px;
                    font-size: 16px;
                  "
                  color="#A6CC39"
                  @click="SearchReport()"
                  >{{ $t("search") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col auto class="px-2">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="ACReportList"
              :search="search"
              hide-default-footer
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              class="pt-6 px-2 report-table"
              item-key="assignmentCourseID"
              :single-expand="true"
              show-expand
            >
              <template v-slot:item.passQuiz="{ item }">
                {{ item.passQuiz === 0 ? "-" : item.passQuiz }}
              </template>
              <template v-slot:item.finishedQuiz="{ item }">
                {{ item.finishedQuiz === 0 ? "-" : item.finishedQuiz }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length - 8"></td>
                <td :colspan="headers.length - 4" style="padding-right: 0">
                  <table class="table">
                    <caption></caption>
                    <tr>
                      <th></th>
                    </tr>
                    <tr v-for="(subItem, i) in item.content" :key="i">
                      <td
                        :colspan="headers.title"
                        class="content-filename text-left"
                      >
                        {{ subItem.title }}
                      </td>
                      <td
                        :colspan="headers.assignedUser"
                        class="sub-content text-center"
                      >
                        {{ subItem.assignedUser }}
                      </td>
                      <td
                        :colspan="headers.finishedUser"
                        class="text-center sub-content"
                      >
                        {{ subItem.finishedUser }}
                      </td>
                      <td
                        :colspan="headers.notFinishedUser"
                        class="text-center sub-content"
                      >
                        {{ subItem.notFinishedUser }}
                      </td>
                      <td
                        :colspan="headers.notStartedUser"
                        class="text-center sub-content"
                      >
                        {{ subItem.notStartedUser }}
                      </td>
                      <td class="sub-content"></td>
                      <td class="sub-content"></td>
                      <td
                        :colspan="headers.detail"
                        class="text-center course-detail"
                      >
                        <v-tooltip
                          top
                          color="#424242"
                          v-model="subItem.subTooltip"
                        >
                          <template v-slot:activator="{ props }">
                            <v-icon
                              v-bind="props"
                              class="detail-icon"
                              color="#ECC407"
                              @click="CourseDetails(item, subItem)"
                              @mouseenter="subItem.subTooltip = true"
                              @mouseleave="subItem.subTooltip = false"
                              >mdi-alert-circle</v-icon
                            >
                          </template>
                          <span>Course Detail</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </table>
                </td>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.actions="{ item }">
                <div v-if="item.quizList.length == 0">
                  <v-tooltip top color="#424242" v-model="item.tooltip">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        class="detail-icon"
                        style="color: #424242; cursor: pointer"
                        @mouseenter="item.tooltip = true"
                        @mouseleave="item.tooltip = false"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>No Quiz</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip top color="#424242" v-model="item.tooltip">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        class="detail-icon"
                        :style="{
                          color: '#ECC407',
                        }"
                        @click="QuizDetails(item)"
                        @mouseenter="item.tooltip = true"
                        @mouseleave="item.tooltip = false"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>Quiz Detail</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row no-gutters class="pa-2 mt-2">
          <v-col cols="12" xs="12" sm="12" lg="10" md="8" xl="10">
            <div class="d-flex text-left">
              <v-pagination
                circle
                v-model="page"
                class="pagination"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="2"
            md="4"
            sm="12"
            xl="2"
            class="d-flex justify-end"
          >
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-1 mr-2"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <!-- course detail popup -->
    <v-dialog
      width="1000"
      v-model="courseDetaildialog"
      persistent
      :scrollable="true"
    >
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <v-icon
            class="mr-1"
            style="padding-bottom: 3px; color: #363636; font-size: 30px"
            >mdi-alert-circle-outline</v-icon
          >
          {{ "Course Detail" }}
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="exportDetailExcel()"
            style="
              color: #a6cc39;
              font-size: 16px;
              border: 1px solid #c0c0c0;
              box-shadow: unset !important;
              border-radius: 8px;
              background: #ffffff 0% 0% no-repeat padding-box;
            "
            class="mr-3 text-capitalize"
          >
            <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
            {{ $t("exportexcel") }}
          </v-btn>
          <v-btn
            icon
            @click="cleardetail()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="6" lg="6" xl="6" md="6" sm="6">
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="course"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("course") }}:</label
                  >
                </v-col>
                <v-col class="pb-3 pl-0">
                  <label
                    for="coursename"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ detail.coursename }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="title"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("title") }}:</label
                  >
                </v-col>
                <v-col class="pb-3 pl-0">
                  <label
                    for="title"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ detail.title }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="filenameL"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("filenameL") }}:</label
                  >
                </v-col>
                <v-col class="pb-3 pl-0">
                  <label
                    for="filename"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ detail.filename }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="courseperiod"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("courseperiod") }}:</label
                  >
                </v-col>
                <v-col class="pb-3 pl-0">
                  <label
                    for="period"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ detail.period }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="Time"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("Time") }}:</label
                  >
                </v-col>
                <v-col class="pb-3 pl-0">
                  <label
                    for="time"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ detail.time }}</label
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-card
                elevation="0"
                style="border-radius: 16px"
                class="mt-0 mx-auto"
                max-width="350"
              >
                <div id="chart" class="customStyles">
                  <apexchart
                    type="donut"
                    :key="chartkey"
                    :options="chartOptionscourse"
                    :series="courseseries"
                  /></div
              ></v-card>
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="statusvideo"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("statusvideo") }}:</label
                  >
                </v-col>
                <v-col style="margin-left: 5px">
                  <div>
                    <v-select
                      :items="statusitems"
                      hide-no-data
                      v-model="statusdata"
                      hide-details
                      item-text="text"
                      item-value="text"
                      @change="(event) => SelectStatus(event)"
                      solo
                      dense
                      multiple
                      clearable
                      placeholder="-- Please select --"
                      style="max-width: 100% !important; margin-right: 15px"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="SelectAllStatus">
                          <v-list-item-action>
                            <v-icon
                              :color="statusitems.length > 0 ? '#a6cc39' : ''"
                              >{{ Statusicon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ statusitems.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </div>
                </v-col>
                <v-col>
                  <v-btn
                    width="120"
                    height="40"
                    class="text-capitalize btn_hover_effect"
                    style="
                      color: #ffff;
                      border: 1px solid #a6cc39;
                      border-radius: 8px;
                      font-size: 16px;
                    "
                    color="#A6CC39"
                    @click="Searchstatus()"
                    >{{ $t("search") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :loading="detailloading"
            loading-text="Loading... Please wait"
            :headers="detailsCourseheader"
            :items="DetailsList"
            class="mt-7 ml-2 mr-2"
            hide-default-footer
            @page-count="detailpageCount = $event"
            :page.sync="detailpage"
            :items-per-page="itemsPerPage"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.status="{ item }">
              <v-chip v-if="item.status == 'Finished'" class="finished">{{
                item.status
              }}</v-chip>
              <v-chip v-if="item.status == 'Not finished'" class="notfinish">{{
                item.status
              }}</v-chip>
              <v-chip v-if="item.status == 'Not started'" class="notstarted">{{
                item.status
              }}</v-chip>
            </template>
          </v-data-table>
          <v-row justify="space-between" class="mx-6 my-4">
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-pagination
                  circle
                  class="pagination"
                  v-model="detailpage"
                  :length="detailpageCount"
                ></v-pagination>
              </v-col>
              <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
                <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="70"
                      v-bind="attrs"
                      v-on="on"
                      style="border: 1px solid #c0c0c0; opacity: 1"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="itemsPerPage = item.title"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- quiz detai popup-->
    <v-dialog
      width="1000"
      v-model="quizDetaildialog"
      persistent
      :scrollable="true"
    >
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <v-icon
            class="mr-1"
            style="padding-bottom: 3px; color: #363636; font-size: 30px"
            >mdi-alert-circle-outline</v-icon
          >
          {{ "Quiz Detail" }}
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="exportQDetailExcel()"
            style="
              color: #a6cc39;
              font-size: 16px;
              border: 1px solid #c0c0c0;
              box-shadow: unset !important;
              border-radius: 8px;
              background: #ffffff 0% 0% no-repeat padding-box;
            "
            class="mr-3 text-capitalize"
          >
            <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
            {{ $t("exportexcel") }}
          </v-btn>
          <v-btn
            icon
            @click="cleardetail()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="6" lg="6" xl="6" md="6" sm="6">
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="course"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("course") }}:</label
                  >
                </v-col>
                <v-col class="pb-3 pl-0">
                  <label
                    for="coursename"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      margin-top: 5px;
                      padding-left: 85px;
                    "
                    >{{ detailQ.coursename }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6" xl="5" md="6" sm="6" class="pr-0 pb-3">
                  <label
                    for="startdate"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("startdate") }}-{{ $t("enddate") }}:</label
                  >
                </v-col>
                <v-col class="pl-1 pb-3">
                  <label
                    for="period"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ detailQ.period }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-3 pt-2">
                  <label
                    for="pretest"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("pretest") }}:</label
                  >
                </v-col>
                <v-col class="pb-3 pt-2 pl-0">
                  <label
                    for="pretest"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      margin-top: 5px;
                      padding-left: 85px;
                    "
                    >{{
                      (detailQ.pretest = detailQ.pretest
                        ? detailQ.pretest
                        : "-")
                    }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6" xl="6" md="6" sm="6" class="pb-3 pt-2">
                  <label
                    for="passingscorepretest"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("passingscorepretest") }}:</label
                  >
                </v-col>
                <v-col style="padding-left: 5px" class="pb-3 pt-2">
                  <label
                    for="pretestPassScore"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{
                      (detailQ.pretestPassScore = detailQ.pretestPassScore
                        ? detailQ.pretestPassScore
                        : "-")
                    }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                  xl="4"
                  md="6"
                  sm="6"
                  class="pt-2 pb-1 pr-0"
                >
                  <label
                    for="posttest"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("posttest") }}:</label
                  >
                </v-col>
                <v-col class="pt-2 pb-1 pl-0">
                  <label
                    for="posttest"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      margin-top: 5px;
                      padding-left: 85px;
                    "
                    >{{
                      (detailQ.posttest = detailQ.posttest
                        ? detailQ.posttest
                        : "-")
                    }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6" xl="6" md="6" sm="6" class="pb-3 pt-2">
                  <label
                    for="passingscoreposttest"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("passingscoreposttest") }}:</label
                  ></v-col
                >
                <v-col style="padding-left: 5px" class="pb-3 pt-2">
                  <label
                    for="posttestPassScore"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{
                      (detailQ.posttestPassScore = detailQ.posttestPassScore
                        ? detailQ.posttestPassScore
                        : "-")
                    }}</label
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-card
                elevation="0"
                style="border-radius: 16px"
                class="mt-0 mx-auto"
                max-width="350"
              >
                <div id="chart" class="customStyles">
                  <apexchart
                    type="donut"
                    :key="chartkey"
                    :options="chartOptionsquiz"
                    :series="quizseriess"
                    :label="labelquiz"
                  /></div
              ></v-card>
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col lg="4" xl="4" md="6" sm="6" class="pb-3 pr-0">
                  <label
                    for="statusvideo"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("statusvideo") }}:</label
                  >
                </v-col>
                <v-col style="margin-left: 5px">
                  <div>
                    <v-select
                      :items="statusitems"
                      hide-no-data
                      v-model="statusdata"
                      hide-details
                      item-text="text"
                      item-value="text"
                      @change="(event) => SelectStatus(event)"
                      solo
                      dense
                      multiple
                      clearable
                      placeholder="-- Please select --"
                      style="max-width: 100% !important; margin-right: 15px"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="SelectAllStatus">
                          <v-list-item-action>
                            <v-icon
                              :color="statusitems.length > 0 ? '#a6cc39' : ''"
                              >{{ Statusicon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ statusitems.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </div>
                </v-col>
                <v-col>
                  <v-btn
                    width="120"
                    height="40"
                    class="text-capitalize btn_hover_effect"
                    style="
                      color: #ffff;
                      border: 1px solid #a6cc39;
                      border-radius: 8px;
                      font-size: 16px;
                    "
                    color="#A6CC39"
                    @click="Searchstatus()"
                    >{{ $t("search") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-show="detailQ.pretest !== '-'" class="mt-5">
            <v-col cols="12" lg="5" xl="6" md="6" sm="6" class="pb-1 pr-0">
              <v-icon style="font-size: 35px" class="mr-1 mb-1"
                >mdi-alert-circle-outline</v-icon
              >
              <span
                style="
                  font-size: 20px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 15px;
                "
                >{{ $t("detail") }} : {{ $t("pretest") }}</span
              >
            </v-col>
          </v-row>

          <v-data-table
            v-show="detailQ.pretest !== '-'"
            :loading="detailloading"
            loading-text="Loading... Please wait"
            :headers="detailsQuizheader"
            :items="DetailsListPre"
            class="mt-7 ml-2 mr-2"
            hide-default-footer
            @page-count="detailpageCount = $event"
            :page.sync="detailpage"
            disable-pagination
            :items-per-page="itemsPerPage"
          >
            <template v-slot:no-data>
              <p style="font-size: 16px">{{ $t("nodata") }}</p>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.scores="{ item }">
              {{ item.scores === "0 / -" ? "-" : item.scores }}
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.passCriteriaQuiz="{ item }">
              <v-sheet
                v-if="
                  item.passCriteriaQuiz !== '-' &&
                  detailQ.pretestPassScore !== '-'
                "
                height="20px"
                width="65px"
                line-height="30px"
                :class="
                  item.passCriteriaQuiz === 'pass'
                    ? 'status_list'
                    : 'status_list1'
                "
              >
                {{ $t(item.passCriteriaQuiz) }}
              </v-sheet>
              <div v-else style="width: 85px; padding-left: 12px">
                {{ "-" }}
              </div>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.statusQuiz="{ item }">
              <v-chip v-if="item.statusQuiz == 'Finished'" class="finished">{{
                item.statusQuiz
              }}</v-chip>
              <v-chip
                v-if="item.statusQuiz == 'Not finished'"
                class="notfinish"
                >{{ item.statusQuiz }}</v-chip
              >
              <v-chip
                v-if="item.statusQuiz == 'Not started'"
                class="notstarted"
                >{{ item.statusQuiz }}</v-chip
              >
              <v-chip v-if="item.statusQuiz == '-'" class="noquiz">{{
                "No Quiz"
              }}</v-chip>
            </template>
          </v-data-table>
          <v-row
            v-show="detailQ.pretest !== '-'"
            justify="space-between"
            class="mx-6 my-4"
          >
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-pagination
                  circle
                  class="pagination"
                  v-model="detailpage"
                  :length="detailpageCount"
                ></v-pagination>
              </v-col>
              <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
                <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="70"
                      v-bind="attrs"
                      v-on="on"
                      style="border: 1px solid #c0c0c0; opacity: 1"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="itemsPerPage = item.title"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
          <v-row class="mt-5" v-show="detailQ.posttest !== '-'">
            <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
              <v-icon style="font-size: 35px" class="mr-1 mb-1"
                >mdi-alert-circle-outline</v-icon
              >
              <span
                style="
                  font-size: 20px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 15px;
                "
                >{{ $t("detail") }} :</span
              >
              &nbsp;
              <span
                style="
                  font-size: 20px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 15px;
                "
                >{{ $t("posttest") }}</span
              >
            </v-col>
          </v-row>
          <v-data-table
            v-show="detailQ.posttest !== '-'"
            :loading="detailloading"
            loading-text="Loading... Please wait"
            :headers="detailsQuizheader"
            :items="DetailsListPost"
            class="mt-7 ml-2 mr-2"
            :search="search"
            hide-default-footer
            @page-count="detailpageCount = $event"
            :page.sync="detailpage"
            :items-per-page="itemsPerPage"
            disable-pagination
          >
            <template v-slot:no-data>
              <p style="font-size: 16px">{{ $t("nodata") }}</p>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.scores="{ item }">
              {{ item.scores === "0 / -" ? "-" : item.scores }}
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.passCriteriaQuiz="{ item }">
              <v-sheet
                v-if="
                  item.passCriteriaQuiz !== '-' &&
                  detailQ.posttestPassScore !== '-'
                "
                height="20px"
                width="65px"
                line-height="30px"
                :class="
                  item.passCriteriaQuiz === 'pass'
                    ? 'status_list'
                    : 'status_list1'
                "
              >
                {{ $t(item.passCriteriaQuiz) }}
              </v-sheet>
              <div v-else style="width: 85px; padding-left: 12px">
                {{ "-" }}
              </div>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.statusQuiz="{ item }">
              <v-chip v-if="item.statusQuiz == 'Finished'" class="finished">{{
                item.statusQuiz
              }}</v-chip>
              <v-chip
                v-if="item.statusQuiz == 'Not finished'"
                class="notfinish"
                >{{ item.statusQuiz }}</v-chip
              >
              <v-chip
                v-if="item.statusQuiz == 'Not started'"
                class="notstarted"
                >{{ item.statusQuiz }}</v-chip
              >
              <v-chip v-if="item.statusQuiz == '-'" class="noquiz">{{
                "No Quiz"
              }}</v-chip>
            </template>
          </v-data-table>
          <v-row
            justify="space-between"
            class="mx-6 my-4"
            v-show="detailQ.posttest !== '-'"
          >
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-pagination
                  circle
                  class="pagination"
                  v-model="detailpage"
                  :length="detailpageCount"
                ></v-pagination>
              </v-col>
              <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
                <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="70"
                      v-bind="attrs"
                      v-on="on"
                      style="border: 1px solid #c0c0c0; opacity: 1"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="itemsPerPage = item.title"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
          <v-row justify="space-between" class="mx-6 my-4"> </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    frommenu_New: false,
    tomenu_New: false,
    startdate_New: "",
    enddate_New: "",

    isTooltipVisible: false,
    detailCopyPre: [],
    detailCopyPost: [],
    detailCopy: [],
    statusItems: [],
    showFilterTools: false,
    //searchtype: null,
    courseCopy: [],
    titleCopy: [],
    searchcourse: null,
    //searchtitle: null,
    breadcrumbs: [
      {
        //image: "house.png"
        sidebar_tilte: "report",
      },
      {
        text: "ACReport", //assignmentcourse
      },
    ],
    Courseitems: [],
    Titleitems: [],
    tempTitle: [],
    values: [],
    frommenu: false,
    tomenu: false,
    course: [],
    startdate: "",
    enddate: "",
    ACReportList: [],
    DetailsList: [],
    DetailsListPre: [],
    DetailsListPost: [],
    detail: {
      coursename: "",
      title: "",
      period: "",
      time: "",
      filename: "",
      quizName: "",
    },
    detailQ: {
      coursename: "",
      period: "",
      pretest: "",
      posttest: "",
      pretestPassScore: "",
      posttestPassScore: "",
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    detailpage: 1,
    detailpageCount: 0,
    courseDetaildialog: false,
    quizDetaildialog: false,
    loading: false,
    detailloading: false,
    search: null,
    dateoffset: new Date().getTimezoneOffset(),
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    statusdata: [],
    statusitems: [],
    statusQuizitems: [],
    statusQuizdata: [],
    courseseries: [],
    quizseriess: [],
    labelquiz: [],
    chartkey: 0,
    chartOptionsquiz: {
      chart: {
        type: "donut",
      },
      labels: [],
      fill: {
        colors: ["#FF3D3D", "#A6CC38"],
      },
      colors: ["#FF3D3D", "#A6CC38"],
      grid: {
        show: true,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val.toFixed(2)}%`; // You can use toFixed to specify decimal places
        },
      },
      legend: {
        show: true,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          // innerSize: 450,
          donut: {
            size: "50%",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function () {
            return "";
          },
          title: {
            formatter: function (seriesName, val) {
              return (
                "<div style='text-align: center;'>" +
                seriesName +
                "<br>" +
                "<span class='tooltip-value'>" +
                val.series[val.seriesIndex] +
                "%</span></div>"
              );
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    chartOptionscourse: {
      chart: {
        type: "donut",
      },
      labels: [],
      fill: {
        colors: ["#FF3D3D", "#A6CC38", "#ffc000"],
      },
      colors: ["#FF3D3D", "#A6CC38", "#ffc000"],
      grid: {
        show: true,
      },
      dataLabels: {
        enabled: true,
        // formatter: function (val, opt) {
        //     return opt.w.config.series[opt.seriesIndex] + " " + val + "%";
        //   },
      },

      legend: {
        show: true,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          // innerSize: 450,
          donut: {
            size: "50%",
          },
        },
      },

      tooltip: {
        y: {
          formatter: function () {
            return "";
          },
          title: {
            formatter: function (seriesName, val) {
              return (
                "<div style='text-align: center;'>" +
                seriesName +
                "<br>" +
                "<span class='tooltip-value'>" +
                val.series[val.seriesIndex] +
                "%</span></div>"
              );
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),
  computed: {
    selectAll() {
      return this.course.length === this.Courseitems.length;
    },
    selectAllTitle() {
      return this.values.length === this.Titleitems.length;
    },
    selectAllStatus() {
      return this.statusdata.length == this.statusitems.length;
    },
    selectAllQuizStatus() {
      return this.statusQuizdata.length == this.statusQuizitems.length;
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Titleicon() {
      if (this.selectAllTitle) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Statusicon() {
      if (this.selectAllStatus) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    QuizStatusicon() {
      if (this.selectAllQuizStatus) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    headers() {
      return [
        {
          text: "#",
          value: "no",
          align: "left",
          width: "80px",
        },
        {
          text: this.$t("course"),
          value: "courseName",
          align: "left",
          width: "250px",
        },
        {
          text: this.$t("courseperiod"),
          value: "coursePeriod",
          align: "center",
          width: "250px",
        },
        {
          text: this.$t("title"),
          value: "title",
          align: "left",
          width: "200px",
        },
        {
          text: this.$t("assigneduser"),
          value: "assignedUser",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("finisheduser"),
          value: "finishedUser",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("notfinishuser"),
          value: "notFinishedUser",
          align: "center",
          width: "200px",
        },
        {
          text: this.$t("notstarteduser"),
          value: "notStartedUser",
          align: "center",
          width: "200px",
        },
        {
          text: this.$t("passquiz"),
          value: "passQuiz",
          align: "center",
          width: "200px",
        },
        {
          text: this.$t("finishedquiz"),
          value: "finishedQuiz",
          align: "center",
          width: "200px",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          align: "center",
          width: "150px",
        },
      ];
    },
    detailsCourseheader() {
      return [
        {
          text: "#",
          align: "center",
          value: "number",
          width: "60px",
        },
        {
          text: "Name-Surname",
          value: "name",
          align: "left",
          width: "190px",
        },
        {
          text: this.$t("group"),
          value: "departmentName",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("firstviewdate"),
          value: "firstViewDate",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("lastviewdate"),
          value: "lastViewDate",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("ViewTime"),
          value: "viewTime",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("statusvideo"),
          value: "status",
          align: "center",
          width: "180px",
        },
      ];
    },
    detailsQuizheader() {
      return [
        {
          text: "#",
          align: "center",
          value: "number",
          width: "60px",
        },
        {
          text: this.$t("fullnamesm"),
          value: "name",
          align: "left",
          width: "190px",
        },
        {
          text: this.$t("group"),
          value: "departmentName",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("lastquizDate"),
          value: "lastQuizDate",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("numOfquiz"),
          value: "numberOfQuiz",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("highscorefullscore"),
          value: "scores",
          align: "center",
          width: "250px",
        },
        {
          text: this.$t("passedthecriteria"),
          align: "left",
          value: "passCriteriaQuiz",
          width: "210px",
        },
        {
          text: this.$t("statusquiz"),
          value: "statusQuiz",
          align: "center",
          width: "180px",
        },
      ];
    },
  },
  mounted() {
    this.GetCourseItems();
    this.GetTitleItems();
  },
  methods: {
    clearAll() {
      this.Courseitems = 0;
      this.values = [];
      this.enddate = null;
      this.startdate = null;
      this.enddate_New = null;
      this.startdate_New = null;
    },
    cleardetail() {
      this.statusitems = [];
      this.statusQuizitems = [];
      this.courseDetaildialog = false;
      this.quizDetaildialog = false;
      this.detail = {};
      this.detailQ = {};
      this.DetailsList = [];
      this.DetailsListPost = [];
      this.DetailsListPre = [];
    },
    clearSearchWord() {
      this.searchcourse = null;
      this.searchInLists();
    },
    searchInLists() {
      if (!this.searchcourse) {
        this.Courseitems = this.courseCopy;
      }

      this.Courseitems =
        this.searchcourse != null
          ? this.courseCopy.filter((x) => {
              return (
                x.courseName
                  .toLowerCase()
                  .indexOf(this.searchcourse.toLowerCase()) > -1
              );
            })
          : this.courseCopy;
    },
    StartViewDate() {
      if (this.startdate > this.enddate) {
        this.enddate = this.startdate;
      }
    },
    StartViewDate_New() {
      if (this.startdate_New > this.enddate_New) {
        this.enddate_New = this.startdate_New;
      }
    },
    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates(val) {
      return val >= this.startdate;
    },
    allowedDates_New: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates_New(val) {
      return val >= this.startdate_New;
    },
    CourseDetails(item, subitem) {
      this.courseDetaildialog = true;
      this.detail.coursename = item.courseName;
      this.detail.title = subitem.title;
      this.detail.period = item.coursePeriod;
      this.detail.time = subitem.time;
      this.detail.filename = subitem.videoName;
      this.GetCourseDetail(item.assignmentCourseID, subitem.titleID);
    },
    QuizDetails(item) {
      this.quizDetaildialog = true;
      this.detailQ.coursename = item.courseName;
      this.detailQ.period = item.coursePeriod;
      for (let q of item.quizList) {
        if (q.quizTypeID === 2) {
          this.detailQ.pretest = q.quizName ? q.quizName : "-";
          this.detailQ.pretestPassScore = q.passingScore ? q.passingScore : "-";
        } else if (q.quizTypeID === 1) {
          this.detailQ.posttest = q.quizName ? q.quizName : "-";
          this.detailQ.posttestPassScore = q.passingScore
            ? q.passingScore
            : "-";
        } else if (q.quizTypeID === 3) {
          this.detailQ.pretest = q.quizName ? q.quizName : "-";
          this.detailQ.pretestPassScore = q.passingScore ? q.passingScore : "-";
          this.detailQ.posttest = q.quizName ? q.quizName : "-";
          this.detailQ.posttestPassScore = q.passingScore
            ? q.passingScore
            : "-";
        }
      }
      this.GetQuizDetail(item.assignmentCourseID, item.titleID);
    },
    SearchReport() {
      if (
        this.values.length == 0 &&
        this.course.length == 0 &&
        this.startdate == "" &&
        this.enddate == "" &&
        this.startdate_New == "" &&
        this.enddate_New == ""
      )
        alert("Please select condition to search!");
      else if (this.startdate == "" && this.enddate != "") {
        alert("Please select a create date to find information");
      } else if (this.startdate_New == "" && this.enddate_New != "") {
        alert("Please select a start date to find information");
      } else {
        this.GetReportItems(2);
      }
    },
    SelectStatus() {
      if (this.statusdata.length == 0) {
        this.DetailsList = this.detailCopy;
      }
    },
    SelectQuiz() {
      if (this.statusQuizdata.length == 0) {
        this.DetailsListPre = this.detailCopyPre;
        this.DetailsListPost = this.detailCopyPost;
      }
    },
    Searchstatus() {
      let searchdata = [];
      let oldData = this.DetailsList;

      if (this.statusdata.length == 0) {
        alert("Please select the status to search!");
        this.DetailsList = this.detailCopy;
      } else {
        if (this.statusdata.length != 0) {
          this.statusdata.forEach((element) => {
            let testdata = oldData.filter((x) => x.status == element);
            searchdata.push(...testdata);
          });
        }
        this.DetailsList = searchdata;
      }
    },
    SearchStatusQuiz() {
      let searchdataPre = [];
      let searchdataPost = [];
      let oldDataPre = this.DetailsListPre;
      let oldDataPost = this.DetailsListPost;

      if (this.statusQuizdata.length == 0) {
        alert("Please select the status to search!");
        this.DetailsListPre = this.detailCopyPre;
        this.DetailsListPost = this.detailCopyPost;
      } else {
        if (this.statusQuizdata.length != 0) {
          this.statusQuizdata.forEach((element) => {
            let testdataPre = oldDataPre.filter((x) => x.statusQuiz == element);
            searchdataPre.push(...testdataPre);
            let testdataPost = oldDataPost.filter(
              (x) => x.statusQuiz == element
            );
            searchdataPost.push(...testdataPost);
          });
        }
        this.DetailsListPre = searchdataPre;
        this.DetailsListPost = searchdataPost;
      }
    },
    getSelectAll() {
      if (this.course.length == 0) {
        this.course = this.Courseitems.map(({ courseID }) => courseID);
      } else {
        this.course = [];
      }
    },
    SelectAllStatus() {
      if (this.statusdata.length == 0) {
        this.statusdata = this.statusitems.map(({ text }) => text);
      } else {
        this.statusdata = [];
      }
    },
    SelectAllQuizStatus() {
      if (this.statusQuizdata.length == 0) {
        this.statusQuizdata = this.statusQuizitems.map(({ text }) => text);
      } else {
        this.statusQuizdata = [];
      }
    },
    async GetCourseItems() {
      let that = this;
      axios
        .get(
          `${that.web_url}Assignment/GetAssignmentCourseReportName?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Courseitems = temp.sort((a, b) =>
              a.courseName.toLowerCase() > b.courseName.toLowerCase() ? 1 : -1
            );
            that.courseCopy = that.Courseitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetTitleItems() {
      let that = this;
      axios
        .get(
          `${that.web_url}Assignment/GetAssignmentCourseReportTitle?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    SelectCourse(value) {
      this.Titleitems = [];
      this.values = [];
      if (value.length > 0) {
        for (let i of value) {
          const found = this.tempTitle.filter((c) => c.courseID == i);
          if (found.length != 0) {
            if (this.Titleitems.length == 0) {
              this.Titleitems = found;
            } else {
              for (let title of found) {
                if (title.title != "") {
                  this.Titleitems.push(title);
                }
              }
            }
          }
        }
      } else {
        this.Titleitems = this.tempTitle;
        this.ACReportList = [];
      }
    },
    async GetReportItems(type) {
      let that = this;
      that.loading = true;
      that.ACReportList = [];
      let request = null;
      if (type == 1) {
        request = {
          companyID: localStorage.getItem("companyID"),
          separateMethod: 1,
          offset: that.dateoffset,
        };
      } else {
        request = {
          companyID: localStorage.getItem("companyID"),
          assignmentCourseID: this.course,
          titleID: that.values,
          startDate: that.startdate_New,
          endDate: that.enddate_New,
          createDate: that.startdate,
          createEndDate: that.enddate,
          separateMethod: 2,
          offset: that.dateoffset,
        };
      }
      await axios
        .post(`${that.web_url}Assignment/AssignmentCourseReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ACReportList = response.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              tooltip: false,
              content: v.content?.map((sub) => ({
                ...sub,
                subTooltip: false,
              })),
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetCourseDetail(ACId, titleid) {
      let that = this;
      that.DetailsList = [];
      that.detailloading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        assignmentCourseID: ACId,
        offset: that.dateoffset,
        titleID: titleid,
      };
      await axios
        .post(`${that.web_url}Assignment/AssignmentCourseDetailReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.courseseries = response.data.data1.map((item) => item.percent);
            that.chartOptionscourse.labels = response.data.data1.map((v) =>
              String(v.status)
            );
            that.chartkey++;
            const statusList = [
              ...new Set(response.data.data.map((item) => item.status)),
            ];

            that.statusitems = statusList.map((status) => {
              return {
                text: status,
                items: response.data.data.filter(
                  (item) => item.status === status
                ),
              };
            });
            that.DetailsList = response.data.data.map((v, i) => ({
              ...v,
              number: i + 1,
              courseName: that.detail.coursename,
              coursePeriod: that.detail.period,
              title: that.detail.title,
              time: that.detail.viewTime,
            }));
            that.detailCopy = that.DetailsList;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item, index) => ({ ...item, no: ++index }));
    },
    async GetQuizDetail(ACId, titleid) {
      let that = this;
      that.DetailsList = [];
      that.detailloading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        assignmentCourseID: ACId,
        offset: that.dateoffset,
        titleID: titleid,
      };
      await axios
        .post(
          `${that.web_url}Assignment/AssignmentCourseQuizDetailReport`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            const statusQuizList = [
              ...new Set(
                response.data.data?.preTestQuizDetail.map(
                  (item) => item.statusQuiz
                )
              ),
              ...new Set(
                response.data.data?.postTestQuizDetail.map(
                  (item) => item.statusQuiz
                )
              ),
            ];
            that.labelquiz = response.data.data1.map((item) => ({
              label: item.status,
            }));
            that.quizseriess = response.data.data1.map((item) => item.percent);
            that.chartOptionsquiz.labels = response.data.data1.map((item) =>
              String(item.status)
            );
            that.chartkey++;
            that.statusQuizitems = [...new Set(statusQuizList)];

            that.statusQuizitems = statusQuizList.map((statusQuiz) => {
              return {
                text: statusQuiz,
                itemsPre: response.data.data?.preTestQuizDetail.filter(
                  (item) => item.statusQuiz === statusQuiz
                ),
                itemsPost: response.data.data?.postTestQuizDetail.filter(
                  (item) => item.statusQuiz === statusQuiz
                ),
              };
            });

            that.DetailsListPre = response.data.data?.preTestQuizDetail.map(
              (v, i) => ({
                ...v,
                number: i + 1,
                scores:
                  (v.totalScore != null && v.totalScore !== 0
                    ? v.totalScore
                    : 0) +
                  " / " +
                  ((v.fullScore != null && v.fullScore !== 0
                    ? v.fullScore
                    : null) || "-"),

                courseName: that.detailQ.coursename,
                coursePeriod: that.detailQ.period,
                pretest: that.detailQ.pretest,
                pretestPassScore: that.detailQ.pretestPassScore,
                passCriteriaQuiz:
                  v.totalScore !== null
                    ? v.totalScore >= that.detailQ.pretestPassScore
                      ? "pass"
                      : "fail"
                    : "-",
              })
            );
            that.DetailsListPost = response.data.data?.postTestQuizDetail.map(
              (v, i) => ({
                ...v,
                number: i + 1,
                scores:
                  (v.totalScore != null && v.totalScore !== 0
                    ? v.totalScore
                    : 0) +
                  " / " +
                  ((v.fullScore != null && v.fullScore !== 0
                    ? v.fullScore
                    : null) || "-"),
                courseName: that.detailQ.coursename,
                coursePeriod: that.detailQ.period,
                posttest: that.detailQ.posttest,
                posttestPassScore: that.detailQ.posttestPassScore,
                passCriteriaQuiz:
                  v.totalScore !== null
                    ? v.totalScore >= that.detailQ.posttestPassScore
                      ? "pass"
                      : "fail"
                    : "-",
              })
            );
            that.detailCopyPre = that.DetailsListPre;
            that.detailCopyPost = that.DetailsListPost;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item, index) => ({ ...item, no: ++index }));
    },
    async exportExcel() {
      const headers = [
        "Course",
        "Course Period",
        "Title",
        "Assigned user",
        "Finished user",
        "Not Finished user",
        "Not Started user",
        "Pass Quiz",
        "Finished Quiz",
      ];
      const keys = [
        "courseName",
        "coursePeriod",
        "title",
        "assignedUser",
        "finishedUser",
        "notFinishedUser",
        "notStartedUser",
        "passQuiz",
        "finishedQuiz",
      ];
      const fileName = `${"AssignmentCourseReport"}.xlsx`;
      const filterItems = this.ACReportList.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);

      for (const item of filterItems) {
        // Process the main item data
        let rowOne = [];
        for (const key of keys) {
          rowOne.push(item[key] || " ");
        }
        rowOne[headers.indexOf("Course")] = item.courseName;
        rowOne[headers.indexOf("CoursePeriod")] = item.coursePeriod;
        rowOne[headers.indexOf("Assigneduser")] = item.assignedUser;
        rowOne[headers.indexOf("PassQuiz")] = item.passQuiz;
        rowOne[headers.indexOf("FinishedQuiz")] = item.finishedQuiz;

        // Push the first row with content values
        data.push([
          item.courseName || " ",
          item.coursePeriod || " ",
          item.content[0]?.title || " ",
          item.content[0]?.assignedUser || " ",
          item.content[0]?.finishedUser || "0" || " ",
          item.content[0]?.notFinishedUser || "0" || " ",
          item.content[0]?.notStartedUser || "0" || " ",
          item.passQuiz || " ",
          item.finishedQuiz || " ",
        ]);

        // Process remaining content rows
        for (let i = 1; i < item.content.length; i++) {
          const contentItem = item.content[i];
          const contentRow = [
            " ", // Empty for courseName
            " ", // Empty for coursePeriod
            contentItem.title || " ",
            contentItem.assignedUser || " ",
            contentItem.finishedUser || "0" || " ",
            contentItem.notFinishedUser || "0" || " ",
            contentItem.notStartedUser || "0" || " ",
            " ", // Empty for passQuiz
            " ", // Empty for finishedQuiz
          ];
          data.push(contentRow);
        }
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("Assignment Course", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 15 : 19);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
    async exportDetailExcel() {
      const headers = [
        "Course",
        "Course Period",
        "Title",
        "Name - Surname",
        "Group",
        "First view date",
        "Last view date",
        "View Time",
        "Status",
      ];
      const keys = [
        "courseName",
        "coursePeriod",
        "title",
        "name",
        "departmentName",
        "firstViewDate",
        "lastViewDate",
        "viewTime",
        "status",
      ];
      const fileName = `${"AssignmentCourse - " + this.detail.title}.xlsx`;
      const filterItems = this.DetailsList.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);
      for (const item of filterItems) {
        let row = [];

        for (const key of keys) {
          row.push(item[key] || 0);
        }
        data.push(row);
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("Assignment Course", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 14 : 16);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
    async exportQDetailExcel() {
      const headers = [
        "Name - Surname",
        "Group",
        "Last Quiz Date",
        "Number of Quiz",
        "High score/Full score",
        "Status Quiz",
      ];
      const headerss = [
        "Name - Surname",
        "Group",
        "Last Quiz Date",
        "Number of Quiz",
        "High score/Full score",
        "Status Quiz",
      ];
      const keyss = [
        "name",
        "departmentName",
        "lastQuizDate",
        "numberOfQuiz",
        "scores",
        "statusQuiz",
      ];
      const keys = [
        "name",
        "departmentName",
        "lastQuizDate",
        "numberOfQuiz",
        "scores",
        "statusQuiz",
      ];
      let fileName;
      const filterItems = this.DetailsListPre.map((x) => ({
        ...x,
        scores: x.scores == "0 / -" ? "-" : x.scores,
        posttest: typeof x.posttest == "undefined" ? "-" : x.posttest,
        posttestPassScore:
          typeof x.posttestPassScore == "undefined" ? "-" : x.posttestPassScore,
      }));
      const filterItemss = this.DetailsListPost.map((x) => ({
        ...x,
        scores: x.scores == "0 / -" ? "-" : x.scores,
        pretest: typeof x.pretest == "undefined" ? "-" : x.pretest,
        pretestPassScore:
          typeof x.pretestPassScore == "undefined" ? "-" : x.pretestPassScore,
      }));
      const workbook = new exceljs.Workbook();

      const worksheet = workbook.addWorksheet("QuizDetails", {
        views: [{ showGridLines: true }],
      });

      if (filterItems.length > 0 && filterItemss.length > 0) {
        fileName = `QuizDetails - ${filterItems[0].courseName}.xlsx`;
        const labels = [
          "Start Date-End Date :",
          "Pre-Test :",
          "Passing Score Pre-Test :",
          "Post-Test :",
          "Passing Score Post-Test :",
        ];

        const values = [
          filterItems[0].coursePeriod,
          filterItems[0].pretest,
          filterItems[0].pretestPassScore,
          filterItemss[0].posttest,
          filterItemss[0].posttestPassScore,
        ];
        const labelColors = ["FF9900", "FF9900", "FF9900", "FF9900", "FF9900"];

        for (let i = 0; i < labels.length; i++) {
          const row = worksheet.addRow([labels[i], values[i]]);
          const labelCell = row.getCell(1);

          labelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: labelColors[i] },
          };
        }
      } else if (filterItems.length > 0) {
        fileName = `QuizDetails - ${filterItems[0].courseName}.xlsx`;
        const labels = [
          "Start Date-End Date :",
          "Pre-Test :",
          "Passing Score Pre-Test :",
          "Post-Test :",
          "Passing Score Post-Test :",
        ];

        const values = [
          filterItems[0].coursePeriod,
          filterItems[0].pretest,
          filterItems[0].pretestPassScore,
          typeof filterItems[0].posttest !== "undefined"
            ? filterItems[0].posttest
            : "-",
          typeof filterItems[0].posttestPassScore !== "undefined"
            ? filterItems[0].posttestPassScore
            : "-",
        ];
        const labelColors = ["FF9900", "FF9900", "FF9900", "FF9900", "FF9900"];

        for (let i = 0; i < labels.length; i++) {
          const row = worksheet.addRow([labels[i], values[i]]);
          const labelCell = row.getCell(1);

          labelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: labelColors[i] }, // Use different colors for each label
          };
        }
      } else {
        fileName = `QuizDetails - ${filterItemss[0].courseName}.xlsx`;
        const labels = [
          "Start Date-End Date :",
          "Pre-Test :",
          "Passing Score Pre-Test :",
          "Post-Test :",
          "Passing Score Post-Test :",
        ];

        const values = [
          filterItemss[0].coursePeriod,
          typeof filterItemss[0].pretest !== "undefined"
            ? filterItemss[0].pretest
            : "-",
          typeof filterItemss[0].pretestPassScore !== "undefined"
            ? filterItemss[0].pretestPassScore
            : "-",
          filterItemss[0].posttest,
          filterItemss[0].posttestPassScore,
        ];
        const labelColors = ["FF9900", "FF9900", "FF9900", "FF9900", "FF9900"];

        for (let i = 0; i < labels.length; i++) {
          const row = worksheet.addRow([labels[i], values[i]]);
          const labelCell = row.getCell(1);

          labelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: labelColors[i] }, // Use different colors for each label
          };
        }
      }
      worksheet.addRow([]);
      if (filterItems.length > 0) {
        const Label = "Detail : Pre-Test";
        const row = worksheet.addRow([Label]);

        const cell = row.getCell(1);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
        };
        worksheet.addRow([...headers]);
        worksheet.getRow(worksheet.rowCount).eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff9900" },
            bgColor: { argb: "ff9900" },
          };
          cell.font = {
            name: "Calibri",
            size: 11,
            color: { argb: "000000" },
            bold: true,
          };
          console.log(number);
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        for (const item of filterItems) {
          let row = [];
          for (const key of keys) {
            row.push(item[key] || 0);
          }
          worksheet.addRow(row);
        }
      }
      worksheet.addRow([]); // Add a blank row between pre-test and post-test

      if (filterItemss.length > 0) {
        const Label = "Detail : Post-Test";
        const row = worksheet.addRow([Label]);

        const cell = row.getCell(1);

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" }, // Yellow color, you can replace it with the color you want
        };
        worksheet.addRow([...headerss]);
        worksheet.getRow(worksheet.rowCount).eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff9900" },
            bgColor: { argb: "ff9900" },
          };
          cell.font = {
            name: "Calibri",
            size: 11,
            color: { argb: "000000" },
            bold: true,
          };
          console.log(number);
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        for (const item of filterItemss) {
          let row = [];
          for (const key of keyss) {
            row.push(item[key] || 0);
          }
          worksheet.addRow(row);
        }
      }

      worksheet.eachRow((row, rowNumber) => {
        // Iterate over cells in the row
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          console.log(rowNumber);
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });
      // set firt cell of 6-rows text to left
      for (let i = 1; i <= 6; i++) {
        const cell = worksheet.getCell(i, 1);
        // Set the text alignment to the left
        cell.alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
      }

      worksheet.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet.columns.forEach((column, i) => {
        if (i === 1) {
          column.width = 30;
          column.eachCell({ includeEmpty: true }, (cell) => {
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          });
        } else {
          column.width = headers[i].length + (i <= 10 ? 15 : 18);
        }
      });

      // Write to buffer and create a Blob for download
      workbook.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
  },
};
</script>

<style scoped>
.label1 {
  margin-left: 10px;
  margin-top: 10px;
}
::v-deep .apexcharts-canvas text.apexcharts-series-label .customStyles {
  display: none;
}
::v-deep .pre-fix .v-input__slot {
  border: 1px solid #707070;
  font-size: 16px !important;
  border-radius: 4px !important;
}
::v-deep .v-input__control {
  align-content: end;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep .v-list-item__content {
  color: #4d4f5c;
}
::v-deep .to-date .v-input__slot {
  width: 200px !important;
}
::v-deep .from-date .v-input__slot {
  width: 200px !important;
}
.report-table tr:nth-child(even) {
  background: unset;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
  word-break: break-all !important;
}
::v-deep .theme--light.v-table {
  margin-top: 2rem;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  background: #f5f9e9;
} /* expanded content */
::v-deep .table {
  table-layout: fixed;
  width: 100%;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__row {
  background: #f1f1f3 !important;
}
.table_header {
  max-width: 200px !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}

::v-deep .v-dialog {
  border-radius: 30px !important;
}
::v-deep .btnStyle .v-btn__content {
  font-size: 20px;
  color: #fff;
}
::v-deep .v-list-item__title {
  color: #4d4f5c !important;
}
div#list-39 {
  width: 185px !important;
}
div#list-42 {
  width: 185px !important;
}
.searchinlist.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
.v-card {
  border-radius: 16px !important;
}
</style>

<style>
.report-table thead th:last-child {
  border-radius: 0px 8px 8px 0px !important;
}
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.finished {
  color: #4fb14e !important;
  background: rgba(111, 220, 66, 0.1) !important;
}
.notstarted {
  color: #ff6060 !important;
  background: rgba(255, 96, 96, 0.1) !important;
}
.noquiz {
  color: gray !important;
  background: #e0e0e087 !important;
}
.notfinish {
  color: #424242 !important;
  background: rgba(66, 66, 66, 0.1) !important;
}
.blue {
  color: #4390df !important;
  background: rgba(67, 144, 223, 0.1) !important;
}
</style>

<style scoped>
::v-deep .v-data-table__expand-icon {
  outline: none !important;
  border-radius: 0px !important;
}
::v-deep .table {
  table-layout: inherit; /**fixed */
  width: 100%;
}
::v-deep .th,
td.content-filename {
  width: 180px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
}
::v-deep td.course-detail {
  width: 150px !important;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
}
::v-deep td.sub-content {
  width: 200px !important;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
}
@media (min-width: 3000px) {
  ::v-deep .th,
  td.content-filename {
    width: 190px;
    overflow: hidden;
    word-break: break-all !important;
    background-color: unset;
    height: 45px;
  }
}
::v-deep .v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: 1px solid;
  border-radius: 50%;
}
::v-deep .detail-icon.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: none !important;
}
::v-deep .v-data-table__expand-icon.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: 1px solid !important;
  border-radius: 50% !important;
  font-size: 20px;
  color: #424242;
}
.status_list {
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  padding: 3px;
  margin: 5px 40px 0px 0px;
  background: #6fdc42;
  color: #ffffff;
  display: inline-table;
}
.status_list1 {
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  padding: 3px;
  margin: 5px 40px 0px 0px;
  background: rgb(239, 4, 4);
  color: #ffffff;
  display: inline-table;
}
</style>
